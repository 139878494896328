import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

function Header() {
  return (
    <div className="header">
      <div className="header-content">
        <div className="header-content-left">
          <Link to="/">SpotifyCharts.me</Link>
        </div>
        <div className="header-content-right">
          <Link to="/about">About</Link>
        </div>
      </div>
    </div>
  )
}

export default Header
