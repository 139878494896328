import React from 'react';
import './index.css';

function Footer() {
  return (
    <div className='footer'>
      <div className="footer-content">
        <div className="footer-content-left">
          Copyright {(new Date()).getFullYear()} @ SpotifyCharts.me
        </div>
        <div className="footer-content-right">
          Not related to Spotify / Images provided from Spotify
        </div>
      </div>
    </div>
  )
}

export default Footer
