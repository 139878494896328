import React from "react";
import './index.css';

function ImageGrid({gridHeaderText, imageObjs}) {
  return (
    <div className="image-grid">
      <div className="image-grid-header">
        {gridHeaderText}
      </div>
      <div className="image-grid-body">
        {
          imageObjs.map((imageObj, i) => {
            return (
              <a href={imageObj.href} key={i} target="_blank" rel="noopener noreferrer" >
                <div className="image-grid-item">
                  <span>{`${i+1} - ${imageObj.name}`}</span>
                  <img src={imageObj.url} alt="album/artist art" />
                </div>
              </a>
            )
          })
        }
      </div>
    </div>
  )
}

export default ImageGrid
