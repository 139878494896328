import React, {useEffect, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import querystring from 'querystring';

import Header from "../Header";
import ResultsControl from "./ResultsControl";
import ImageGrid from "./ImageGrid";
import ContentList from "./ContentList";
import Footer from "../Footer";

import './index.css';

function ResultsPage() {
  const [topTracksAndArtists, setTopTracksAndArtists] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState('artists');
  const [selectedView, setSelectedView] = useState('grid');

  const artistsToImageObjs = artists => {
    return artists.map(artist => {
      return {
        name: artist.name,
        url: getArtistImage(artist),
        href: artist.external_urls.spotify
      }
    })
  }

  const artistsToListObjs = artists => {
    return artists.map(artist => {
      return {
        name: artist.name,
        followers: artist.followers.total.toLocaleString()
      }
    })
  }

  const getArtistImage = artist => {
    return artist.images[1].url;
  }

  const getParamsAsObj = () => {
    const url = window.location.href;
    const params = url.split('?')[1];
    return querystring.parse(params);
  };

  const getTrackAlbumImage = track => {
    return track.album.images[1].url;
  }

  const getTrackArtists = track => {
    return track.artists.map(artist => {
      return artist.name;
    }).join(', ')
  }

  const tracksToImageObjs = tracks => {
    return tracks.map(track => {
      return {
        name: track.name,
        url: getTrackAlbumImage(track),
        href: track.external_urls.spotify
      }
    })
  }

  const tracksToListObjs = tracks => {
    return tracks.map(track => {
      return {
        name: track.name,
        album: track.album.name,
        artists: getTrackArtists(track)
      }
    })
  }

  const renderArtistData = (allArtists, view) => {
    if (view === 'list') {
      return renderArtistLists(allArtists);
    }
    return renderArtistGrids(allArtists);
  }

  const renderArtistGrids = allArtists => {
    return (
      <div className="artist-grids">
        <ImageGrid
          gridHeaderText="Last 4 Weeks"
          imageObjs={artistsToImageObjs(allArtists['short_term']['items'])}
        />
        <ImageGrid
          gridHeaderText="Last 6 Months"
          imageObjs={artistsToImageObjs(allArtists['medium_term']['items'])}
        />
        <ImageGrid
          gridHeaderText="All Time"
          imageObjs={artistsToImageObjs(allArtists['long_term']['items'])}
        />
      </div>
    );
  }

  const renderArtistLists = allArtists => {
    return (
      <div className="artist-lists">
        <ContentList
          columns={['Rank', 'Name', 'Followers']}
          columnWidths={['1fr', '4fr', '2fr']}
          listHeaderText="Last 4 Weeks"
          listObjs={artistsToListObjs(allArtists['short_term']['items'])}
        />
        <ContentList
          columns={['Rank', 'Name', 'Followers']}
          columnWidths={['1fr', '4fr', '2fr']}
          listHeaderText="Last 6 Months"
          listObjs={artistsToListObjs(allArtists['medium_term']['items'])}
        />
        <ContentList
          columns={['Rank', 'Name', 'Followers']}
          columnWidths={['1fr', '4fr', '2fr']}
          listHeaderText="All Time"
          listObjs={artistsToListObjs(allArtists['long_term']['items'])}
        />
      </div>
    )
  }

  const renderTrackData = (allTracks, view, isMobile) => {
    if (view === 'list') {
      return renderTrackList(allTracks, isMobile);
    }
    return renderTrackGrids(allTracks);
  }

  const renderTrackGrids = allTracks => {
    return (
      <div className="artist-grids">
        <ImageGrid
          gridHeaderText="Last 4 Weeks"
          imageObjs={tracksToImageObjs(allTracks['short_term'].items)}
        />
        <ImageGrid
          gridHeaderText="Last 6 Months"
          imageObjs={tracksToImageObjs(allTracks['medium_term'].items)}
        />
        <ImageGrid
          gridHeaderText="All Time"
          imageObjs={tracksToImageObjs(allTracks['long_term'].items)}
        />
      </div>
    );
  }

  const renderTrackList = (allTracks, isMobile) => {
    const trackColumns = isMobile
      ? ['Rank', 'Name', 'Artists']
      : ['Rank', 'Name', 'Artists', 'Album'];

    const trackColumnWidths = isMobile
      ? ['1fr', '4fr', '3fr']
      : ['1fr', '4fr', '3fr', '3fr']

    return (
      <div className="artist-lists">
        <ContentList
          columns={trackColumns}
          columnWidths={trackColumnWidths}
          listHeaderText="Last 4 Weeks"
          listObjs={tracksToListObjs(allTracks['short_term'].items)}
        />
        <ContentList
          columns={trackColumns}
          columnWidths={trackColumnWidths}
          listHeaderText="Last 6 Months"
          listObjs={tracksToListObjs(allTracks['medium_term'].items)}
        />
        <ContentList
          columns={trackColumns}
          columnWidths={trackColumnWidths}
          listHeaderText="All Time"
          listObjs={tracksToListObjs(allTracks['long_term'].items)}
        />
      </div>
    )
  }

  const updateSelectedItemType = itemType => {
    if (selectedItemType === itemType) {
      return;
    }
    if (itemType === 'tracks') {
      setSelectedView('list');
    }
    if (itemType === 'artists') {
      setSelectedView('grid');
    }
    setSelectedItemType(itemType);
  }

  useEffect(() => {
    const params = getParamsAsObj();
    params.redirectURI = `${window.location.origin}/results`
    axios.get("/topTracksAndArtists", { params })
      .then(res => {
        setTopTracksAndArtists(res.data);
      })
      .catch(err => {
        console.error(err);
      })
  }, [])

  const allArtists = topTracksAndArtists != null ? topTracksAndArtists['artists'] : null;
  const allTracks = topTracksAndArtists != null ? topTracksAndArtists['tracks'] : null;

  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  return (
    <div className="results-page">
      <Header />
      <div className="results-body">
        <div className="results-content">
          <ResultsControl
            selectedItemType={selectedItemType}
            selectedView={selectedView}
            onSelectItemType={updateSelectedItemType}
            onSelectView={setSelectedView}
          />
          <div className="results-separator" />
          <div className="results-data">
            {
              (selectedItemType === 'artists' && allArtists) &&
              renderArtistData(allArtists, selectedView)
            }
            {
              (selectedItemType === 'tracks' && allTracks) &&
              renderTrackData(allTracks, selectedView, isMobile)
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ResultsPage
