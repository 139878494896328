import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import AppsIcon from '@material-ui/icons/Apps';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import './index.css';

function ResultsControl({selectedItemType, selectedView, onSelectItemType, onSelectView}) {

  const getItemTypeVariant = (itemType) => {
    if (selectedItemType === itemType) {
      return 'contained';
    }
    return 'outlined';
  }

  const getViewIconColor = view => {
    if (view === selectedView) {
      return 'primary';
    }
    return 'disabled';
  }

  return (
    <div className="results-control">
      <div className="artists-tracks-toggle">
        <ButtonGroup color="primary">
          <Button
            variant={getItemTypeVariant('artists')}
            onClick={() => onSelectItemType('artists')}>
            Artists
          </Button>
          <Button
            variant={getItemTypeVariant('tracks')}
            onClick={() => onSelectItemType('tracks')}>
            Tracks
          </Button>
        </ButtonGroup>
      </div>
      <div className="grid-list-toggle">
        <AppsIcon
          color={getViewIconColor('grid')}
          fontSize="large"
          onClick={() => onSelectView('grid')}
        />
        <span className="grid-list-toggle-separator">|</span>
        <FormatListNumberedIcon
          color={getViewIconColor('list')}
          fontSize="large"
          onClick={() => onSelectView('list')}
        />
      </div>
    </div>
  )
}

export default ResultsControl
