import React from 'react';
import './index.css';

function ContentList({columns, columnWidths, listHeaderText, listObjs}) {

  const gridStyle = {
    'gridTemplateColumns': columnWidths.join(' ')
  }

  return (
    <div className="content-list">
      <div className="content-list-title">
        {listHeaderText}
      </div>
      <div className="content-list-body">
        <div className="header-row" style={gridStyle}>
          {columns.map((column, i) => {
            return (
              <div className="header-row-cell" key={i}>
                {column}
              </div>
            )
          })}
        </div>
        <div className="list-rows">
          {listObjs.map((listObj, i) => {
            return (
              <div className="list-row" key={i} style={gridStyle}>
                {columns.map((column, j) => {
                  return (
                    <div className="list-row-cell" key={j}>
                      {
                        column.toLowerCase() === 'rank'
                          ? (i+1)
                          : listObj[column.toLowerCase()]
                      }
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ContentList
