import React from 'react';
import querystring from "querystring";
import { Link } from "react-router-dom";
import "./index.css";

function LandingPage() {

  // based off of a code block in Spotify's authorization code example
  const generateRandomString = (length) => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  const goToSpotifyLogin = () => {
    const scope = 'user-top-read';
    const client_id = '1cb50c3a8cda49ce9de9af55c1adeeb5'; // can be public
    const redirect_uri = `${window.location.origin}/results`;
    const state = generateRandomString(16);

    const queryValues = querystring.stringify({
      response_type: 'code',
      client_id: client_id,
      scope: scope,
      redirect_uri: redirect_uri,
      state: state,
      show_dialog: true
    });

    window.location.href = `https://accounts.spotify.com/authorize?${queryValues}`;
  }

  return (
    <div className="landing-page">
      <div className="landing-page-content">
        <div className="title">
          Personal Spotify Charts
        </div>
        <div className="title-info">
          A simple tool to view your top tracks and artists over various timeframes
        </div>
        <div className="title-info">
          Login through Spotify below to see your listening data
        </div>
        <div className="action-button" onClick={goToSpotifyLogin}>
          Login
        </div>
        <div className="content-footer">
          <Link to="/about">
            About
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
