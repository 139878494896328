import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import ResultsPage from "./components/ResultsPage";
import LandingPage from "./components/LandingPage";
import AboutPage from "./components/AboutPage";

function App() {

  // didn't feel like creating a whole stylesheet for this
  const appStyle = {
    'height': '100%'
  }

  return (
    <div style={appStyle}>
      <Router>
        <Switch>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/results">
            <ResultsPage />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
