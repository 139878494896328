import React from 'react';
import Header from "../Header";
import './index.css';

function AboutPage() {
  return (
    <div className="about-page">
      <Header />
      <div className="about-body">
        <div className="about-text-content">
          <h1>
            About
          </h1>
          <p>
            Hi! This is a minimal web app where users can see their top tracks and artists from
            the last 4 weeks, the last 6 months, or all time.
          </p>
          <p>
            Data is obtained using the Spotify API after users login through Spotify.
            &nbsp;
            <a href="https://developer.spotify.com/documentation/web-api/" className="link">
              (https://developer.spotify.com/documentation/web-api/)
            </a>
          </p>
          <p>
            To contact the owner of this website, please email <span className="email">spotifychartsme@gmail.com</span>
          </p>
          <p>
            Some info/disclaimers:
          </p>
          <ul>
            <li>Currently this web app is not monetized.</li>
            <li>SpotifyCharts.me has no relation to Spotify AB.</li>
            <li>No user data is stored, client-side or server-side.</li>
            <li>All images used are owned by their respective copyright owners.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AboutPage
